import Http from "../../common/utils/Http";

export const updatePassword = async (params) => {
    try {
        let response = await Http.post('pr/settings/updateAccountPassword', params);
        return response;
    } catch (error) {
        throw error;
    }
}

export const getAccountEmail = async () => {
    try {
        let response = await Http.get('pr/settings/accountDetails');
        return response;
    } catch (error) {
        throw error;
    }
}
