import { formatCurrency } from "../../../../common/utils/helper";
import { Box } from "@mui/material";
import Ptable from "../../../../common/components/base/table/Table";
import React from "react";
import CardStatementHistoryPeriod from "./CardStatementHistoryPeriod";
const { DateTime } = require("luxon");

const cardDetailsHeaders = [
    "CARD NUMBER",
    "NAME",
    "BALANCE",
    "STATUS"
]

const cardDetailsFields = [
    "ACCOUNTNUMBER",
    "NAME",
    "BALANCE",
    "STATUS"
]

const cardStatementHeaders = [
    "DATE",
    "DESCRIPTION",
    "AMOUNT",
    "BALANCE"
]

const cardStatementFields = [
    "TRANSACTIONDATE",
    "TRANSACTIONDESCRIPTION",
    "TRANSACTIONAMOUNT",
    "TRANSACTIONBALANCE"
]

export default function CardStatementList({ statement }) {
    const getCardData = () => {
        return {
            ...statement.data,
            id: 'profile-history-profile',
            BALANCE: formatCurrency(statement.data.VOUCHER.BALANCEAMOUNT),
            STATUS: statement.data.VOUCHER.STATUS,
            NAME: `${statement.data.ACCOUNTCARDFIRSTNAME} ${statement.data.ACCOUNTCARDSURNAME}`
        }
    }

    const getTransactionData = () => {
        let data = statement.data.LISTSTATEMENT.map((item, index) => {
            return {
                ...item,
                TRANSACTIONAMOUNT: formatCurrency(+item.TRANSACTIONVALUE),
                TRANSACTIONBALANCE: formatCurrency(item.TRANSACTIONBALANCE),
                id: item.TRANSACTIONID,
                TRANSACTIONDATE:DateTime.fromSQL(item.TRANSACTIONDATE).toFormat('dd LLL yyyy')
            }
        })
        return data;
    }

    const [errorMessage] = statement.errorMessages;

    return (
        <>
            <Box>
                {statement.data &&
                    <Ptable
                        tableHeaders={cardDetailsHeaders}
                        tableBodies={cardDetailsFields}
                        data={[getCardData()]}
                        testId="card-statement-list-table"
                    />}
            </Box>
            <Box sx={{ mt: 10 }}>
                <CardStatementHistoryPeriod />
                <Ptable
                    noDataFoundMessage={errorMessage}
                    tableHeaders={cardStatementHeaders}
                    tableBodies={cardStatementFields}
                    data={(!errorMessage && statement.data) ? getTransactionData() : []}
                />
            </Box>
        </>
    )
}
