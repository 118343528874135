import { COMPLEX_PASSWORD_VALIDATION_MESSAGE } from "../constants/Constant";
import { REGEX_PASSWORD } from "./regex";

export const emailValidation = (val) => {
    if (!val) return false;
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-useless-escape
    return re.test(String(val).toLowerCase());
};

export const validatePassword = value => {
    if (!value) {
      return 'Password is required.';
    }
    return REGEX_PASSWORD.test(value) ? '' : COMPLEX_PASSWORD_VALIDATION_MESSAGE;
};

export const validateIfFieldValueExists = (name, value) => {
  if (!value) {
    return `${name} is required.`;
  }
  return '';
};
