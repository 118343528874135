import React, { useEffect, useState } from 'react';
import { closeDialog, renderMfaChallengeDialog } from '../slice';
import { useDispatch, useSelector } from 'react-redux';
import Input from '../../common/components/base/input/Input';
import { Alert, Box, Grid, Typography } from '@mui/material';
import Button from '../../common/components/base/button/Button';
import { verifyLogin } from '../thunk';
import MfaState from "../../common/constants/MfaState";
import { useNavigate } from "react-router-dom";

const MfaForm = () => {
    const { username, password, message } = useSelector(state => state.login.mfaData);
    const VERIFY_LOGIN_FULFILLED = "login/verify_login/fulfilled";
    const [stateValues, setStateValues] = useState({
        username: username,
        password: password,
        recaptchaResponse: '',
        challenge: '',
    })

    const [validationErrors, setValidationErrors] = useState({
        challenge: ''
    })

    const dispatch = useDispatch();

    const navigate = useNavigate();
    const isAuthenticated = useSelector(state => state.login.isAuthenticated);
    let multipleAccounts = JSON.parse(localStorage.getItem('multiple_accounts'));

    useEffect(() => {
        if (isAuthenticated && multipleAccounts) {
            navigate("/selectProfile")
        } else if (isAuthenticated) {
            navigate("/dashboard");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated])

    const handleMfaChallengeResponse = (dispatch, response) => {
        const { challengeName } = response.payload;
        const isLoginFulfilled = response.type === VERIFY_LOGIN_FULFILLED;
        const isMfaChallengeRequired = challengeName === MfaState.mfaChallengeRequired;

        if (isLoginFulfilled) {
            if (isMfaChallengeRequired) {
                dispatch(renderMfaChallengeDialog())
            } else {
                dispatch(closeDialog())
            }
        }
    };

    const handleInput = input => event => {
        let value = event.target.value;
        if (input === "challenge" && value.length === 0) {
            setValidationErrors(validationErrors => ({ ...validationErrors, challenge: "OTP Code is required." }));
        }
        setStateValues(prevStateValues => ({ ...prevStateValues, [input]: value }));
    };

    const handleSubmit = () => {
        let errorCount = 0;
        if (stateValues.challenge.length === 0) {
            errorCount++;
            setValidationErrors(validationErrors => ({ ...validationErrors, challenge: "OTP Code is required." }));
        }
        if (errorCount === 0) {
            dispatch(verifyLogin(stateValues))
                .then(response => {
                    handleMfaChallengeResponse(dispatch, response);
                })
        }
    }

    return (
        <Grid container spacing={1} p={1}>
            <Grid item xs={12} display="flex">
                <Typography variant="body2" mt={0.5} ml={0.5}>Please input the authcode you have received to complete
                    login.</Typography>
            </Grid>
            {!!message && (
                <Grid item xs={12} display="flex">
                    <Alert sx={{ width: '100%' }} key="danger" variant="outlined" severity="error"
                            mt={0.5} ml={0.5}>
                        <div data-testid="alert-message">{message}</div>
                    </Alert>
                </Grid>
            )}
            <Grid item xs={12} display="flex">
                <Input
                    size="small"
                    inputLabel="OTP Code"
                    name="challenge"
                    fullWidth
                    autoComplete="otp-code"
                    autoFocus
                    value={stateValues.challenge}
                    onChange={handleInput("challenge")}
                    error={!!validationErrors.challenge}
                    helperText={validationErrors.challenge}
                    data-testid="otp-code-field"
                />
            </Grid>
            <Grid item xs={12}>
                <Box textAlign='center'>
                    <Button
                        variant="contained"
                        size="medium"
                        onClick={handleSubmit}
                        data-testid="btn-otp-submit"
                    >
                        Verify
                    </Button>
                </Box>
            </Grid>
        </Grid>
    );
}

export default MfaForm;
